import { useEffect, useState } from 'react';
import apiClient from "../auth/apiClient";
import PayOnEntryOfferScheduleService from '../services/PayOnEntryOfferScheduleService';
import ICalParser from 'ical-js-parser';
import { rrulestr } from 'rrule';
import { useSelector, shallowEqual } from 'react-redux';
import moment from 'moment';

const poeOfferScheduleService = new PayOnEntryOfferScheduleService(apiClient);

export const usePOEOfferSchedule = (offerId) => {
  const [poeOfferSchedules, setPOEOfferSchedules] = useState();
  const [poeOfferSchedulesLoading, setPOEOfferSchedulesLoading] = useState(true);
  const [poeOfferSchedulesError, setPOEOfferSchedulesError] = useState();
  const timeZone = useSelector(
    (state) =>
      state.entities.Context?.details?.timezone
        ? state.entities.Context.details.timezone
        : moment.tz.guess(),
    shallowEqual
  );
  const daysOfWeek = [
    {
      id: 6,
      name: 'Sunday'
    },
    {
      id: 0,
      name: 'Monday'
    },
    {
      id: 1,
      name: 'Tuesday'
    },
    {
      id: 2,
      name: 'Wednesday'
    },
    {
      id: 3,
      name: 'Thursday'
    },
    {
      id: 4,
      name: 'Friday'
    },
    {
      id: 5,
      name: 'Saturday'
    }
  ];

  const getPOEOfferScheduleDetails = async () => {
    try {
      const response = await poeOfferScheduleService.getPOEOfferScheduleByOfferId(offerId);
      const offerSchedules = response.data.offerSchedules;
      let offerScheduleDetails;

      if (offerSchedules === '') {
        offerScheduleDetails = {
          events: []
        }
      } else {
        const icalJson = ICalParser.toJSON(offerSchedules);
        const icalEvents = icalJson.events.filter((icalEvent) => {
          if (!icalEvent.rrule) {
            return icalEvent
          }
        });
        const modifyDateString = (dateString) => {
          const year = dateString.slice(0, 4);
          const month = dateString.slice(4, 6)
          const day = dateString.slice(6, 8)
          const newDateString = `${year}-${month}-${day}`
          
          return newDateString
        }
        const events = icalEvents.map((event) => {
          const iCalDateStart = event.dtstart.value;
          const iCalDateEnd = event.dtend.value
          const eventStart = iCalDateStart.includes('T') ? moment(iCalDateStart).toISOString() : new Date(modifyDateString(iCalDateStart)).toISOString()
          const eventEnd = iCalDateEnd.includes('T') ? moment(iCalDateEnd).toISOString() : new Date(modifyDateString(iCalDateEnd)).toISOString()

          return {
            id: event.uid,
            start: eventStart,
            end: eventEnd
          }
        }).sort((a, b) => new Date(a.start) - new Date(b.start));
        const recurrenceEvent = icalJson.events.find((event) => event.rrule);
        
        offerScheduleDetails = events.length > 0 ? { events: events } : {};

        if (recurrenceEvent) {
          const recurrence = rrulestr(recurrenceEvent.rrule);
          let byDaysOfWeek = [];

          if (recurrence.options.byweekday) {
            byDaysOfWeek = recurrence.options.byweekday.map((dayId) => {
              const dayOfWeek = daysOfWeek.find((day) => day.id === dayId);
  
              return dayOfWeek.name;
            });
          }

          offerScheduleDetails.recurrence = {
            id: recurrenceEvent.uid,
            frequency: recurrence.options.freq,
            start: moment(recurrenceEvent.dtstart.value).toISOString(),
            end: moment(recurrenceEvent.dtend.value).toISOString(),
            until: moment(recurrence.options.until).toISOString(),
            bydays: byDaysOfWeek,
            interval: recurrence.options.interval
          }
        }
      }

      return offerScheduleDetails;
    } catch (error) {
      throw new Error(error);
    }
  }

  const createPOEOfferSchedule = async (offerSchedule) => {
    try {
      setPOEOfferSchedulesLoading(true);
      await poeOfferScheduleService.createPOEOfferSchedule(offerSchedule);
      
      if (!offerSchedule.recurrence) {
        const poeOfferScheduleDetails = await getPOEOfferScheduleDetails();
        
        setPOEOfferSchedules(poeOfferScheduleDetails);
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      setPOEOfferSchedulesLoading(false);
    }
  }

  const updatePOEOfferSchedule = async (offerSchedule) => {
    try {
      setPOEOfferSchedulesLoading(true);

      await poeOfferScheduleService.updatePOEOfferSchedule(offerSchedule);
      const poeOfferScheduleDetails = await getPOEOfferScheduleDetails();

      setPOEOfferSchedules(poeOfferScheduleDetails)
    } catch (error) {
      throw new Error(error);
    } finally {
      setPOEOfferSchedulesLoading(false);
    }
  }

  const deletePOEOfferSchedule = async (scheduleId) => {
    try {
      setPOEOfferSchedulesLoading(true);

      await poeOfferScheduleService.deletePOEOfferSchedule(scheduleId);
      const poeOfferScheduleDetails = await getPOEOfferScheduleDetails();

      setPOEOfferSchedules(poeOfferScheduleDetails)
    } catch (error) {
      throw new Error(error);
    } finally {
      setPOEOfferSchedulesLoading(false);
    }
  }

  useEffect(() => {
    const getPOEOfferSchedules = async () => {
      try {
        const poeOfferScheduleDetails = await getPOEOfferScheduleDetails();

        setPOEOfferSchedules(poeOfferScheduleDetails)
      } catch (error) {
        setPOEOfferSchedulesError(error.message.toString());
      } finally {
        setPOEOfferSchedulesLoading(false)
      } 
    }

    getPOEOfferSchedules();
  }, [offerId])

  return {
    poeOfferSchedules,
    poeOfferSchedulesLoading,
    poeOfferSchedulesError,
    daysOfWeek,
    timeZone,
    createPOEOfferSchedule,
    updatePOEOfferSchedule,
    deletePOEOfferSchedule
  }
}