import { useCallback, useEffect, useState, useRef } from "react";

/* Hooks */
import useFirstRender from "../useFirstRender";

/* State */
import { useSelector, shallowEqual } from "react-redux";

/* Utilities */
import * as _ from "lodash";

const useCallCenterState = () => {
  const [isUserViewingCall, setIsUserViewingCall] = useState(false);
  const [newCall, setNewCall] = useState(false);
  const viewingUsersByEmail = useSelector(
    (state) => state.callcenter?.Queue?.flatMap((items) => items.viewing) ?? [],
    shallowEqual
  );
  const calls = useSelector((state) =>
    _.orderBy(state.callcenter.Queue, ["callStart"], ["desc"]),shallowEqual
  );
  const previousCallCount = usePrevious(calls.length);
  const isFirstRender = useFirstRender();

  useEffect(() => {
    if (calls.length > 0 && isFirstRender) setNewCall(true);
    else if (calls.length > previousCallCount) setNewCall(true);
    else setNewCall(false);
  }, [calls.length]);

  useEffect(() => {
    setIsUserViewingCall(hasCallBeenViewed());
  }, [JSON.stringify(viewingUsersByEmail), calls]);

  const hasCallBeenViewed = useCallback(() => {
    let allCallsViewed = true;
    calls.forEach((call) => {
      if (
        call.viewing?.length === 0 ||
        _.isUndefined(call.viewing) ||
        _.isUndefined(call.viewing?.[0])
      )
        allCallsViewed = false;
    });
    return allCallsViewed;
  }, [viewingUsersByEmail]);

  return { isUserViewingCall, newCall };
};

export default useCallCenterState;

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
