import { useSelector, shallowEqual } from "react-redux";
import { GetAllScopedPermissions } from "../state/slices/entities";

const useScopedPermissions = () => {
  return useSelector((state) => {
    return GetAllScopedPermissions(state?.entities?.EntityList);
  }, shallowEqual);
};

export default useScopedPermissions;
