import { useEffect, useState } from "react";
import {
  HubConnectionBuilder,
  HubConnectionState,
  LogLevel
} from "@microsoft/signalr";
import AppConfig from "AppConfig";

export default function usePortalHubConnection() {
  const [connection, setConnection] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [connectionState, setConnectionState] = useState(
    HubConnectionState.Disconnected
  );

  useEffect(() => {
    setIsOpen(connectionState == HubConnectionState.Connected);
  }, [connectionState]);

  const start = accessToken => {
    stop();

    setConnection(undefined);

    var newConnection = new HubConnectionBuilder()
      .withUrl(AppConfig.hubs.portalHubHost, {
        accessTokenFactory: () => accessToken,
        transport: 1,
        skipNegotiation: true
      })
      .withAutomaticReconnect()
      .configureLogging(LogLevel.Debug)
      .build();

    setConnection(newConnection);
  };

  const stop = () => {
    if (connection) {
      try {
        connection.stop();
      } catch {
        console.warn("Failed to stop conenction");
      }
    }
  };

  useEffect(() => {
    if (!connection) {
      return;
    }

    connection
      .start()
      .then(() => {
        setConnectionState(connection.state);

        connection.onclose(e => {
          setConnectionState(connection.state);
        });

        connection.onreconnecting(error => {
          setConnectionState(connection.state);
        });

        connection.onreconnected(connectionId => {
          setConnectionState(connection.state);
        });
      })
      .catch(() => {
        setConnectionState(connection.state);
      });
  }, [connection]);

  return {
    isOpen,
    connectionState,
    connection,
    start,
    stop
  };
}
