import { useEffect, useState } from "react";

export default function useTimeout({ onTimeout }) {
  const [expireTime, setExpireTime] = useState(null);
  const [timeoutObj, setTimeoutObj] = useState();

  const cancelTimeout = () => {
    if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
  };

  const setExpirationDt = (expirationDt) => {
    if (!expirationDt) {
      return;
    }
    setExpireTime(expirationDt);
  };

  useEffect(() => {
    if (!expireTime || expireTime < new Date()) {
      return;
    }

    var secondsUntilExpiration = Math.abs((new Date() - expireTime) / 1000);
    if (secondsUntilExpiration) {
      cancelTimeout();
      setTimeoutObj(
        setTimeout(function() {
          onTimeout();
        }, secondsUntilExpiration * 1000)
      );
    }
  }, [expireTime]);

  return {
    setExpirationDt,
    cancelTimeout,
  };
}
