import { useEffect, useState } from "react";

export default function useTurnServerSettings() {
  const [hasSettings, setHasSettings] = useState(false);
  const [turnSettings, setTurnSettings] = useState(null);
  const stunUrlReplacement = "<STUN_URL>";
  const turnUrlReplacement = "<TURN_URL>";

  useEffect(() => {
    if (process.env.TURN_URL) {
      setTurnSettings({
        stunUrl: process.env.STUN_URL,
        turnUrl: process.env.TURN_URL,
        turnUserName: process.env.TURN_USERNAME,
        turnPassword: process.env.TURN_PASSWORD,
      });
    }
  }, []);
  useEffect(() => {
    setHasSettings(turnSettings ? true : false);
  }, [turnSettings]);

  const filterOutTemplatedItems = (settingsArray) => {
    if (!settingsArray || hasSettings) {
      return settingsArray;
    }

    return settingsArray.filter(
      (x) => x.urls && !x.urls.includes(stunUrlReplacement) && !x.urls.includes(turnUrlReplacement)
    );
  };

  const replaceTurnSettings = (settingsArray) => {
    if (!settingsArray) {
      return settingsArray;
    }

    if (!hasSettings) {
      return filterOutTemplatedItems(settingsArray);
    }

    return settingsArray.map((x) => {
      if (x.urls) {
        x.urls = x.urls.replace(stunUrlReplacement, turnSettings.stunUrl);
        x.urls = x.urls.replace(turnUrlReplacement, turnSettings.turnUrl);
      }

      if (x.username) {
        x.username = x.username.replace(
          "<TURN_USERNAME>",
          turnSettings.turnUserName
        );
      }
      if (x.credential) {
        x.credential = x.credential.replace(
          "<TURN_PASSWORD>",
          turnSettings.turnPassword
        );
      }

      return x;
    });
  };

  return {
    hasSettings: hasSettings,
    turnSettings: turnSettings,
    replaceTurnSettings: replaceTurnSettings,
  };
}
