import {useEffect, useState, useCallback} from "react";
import {setEntityProperty} from "../state/slices/entities";
import useCancellationToken from "./useCancellationToken";
import apiClient from "../auth/apiClient";
import DeviceStateService from "../services/DeviceStateService";

const useDeviceStateLoader = ({portalHub, dispatch, contextID}) => {
  const service = new DeviceStateService(apiClient);

  const {execute: executeLoadState} = useCancellationToken({
    func: async ({entityID, cancelToken}) => {
      const res = await service.retrieveState(entityID, cancelToken);
      const devices = res.data.Devices;
      devices.forEach((device) => {
        dispatch(
          setEntityProperty({
            entityid: device.DeviceId,
            property: "state",
            value: device.Connected ? device.Dsd : null,
          })
        );
      });
    },
    errHandleFunc: (e) => {
      console.error(e, "Failed to load state")
    },
  });

  useEffect(() => {
    if (portalHub.isOpen && contextID) {
      executeLoadState({entityID: contextID});
    }
  }, [portalHub.isOpen, contextID]);

  return {};
};

export default useDeviceStateLoader;
