import React from 'react';
import { useConfirmationDialog } from "../../hooks/useConfirmationDialog";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOut } from "@fortawesome/pro-duotone-svg-icons";
import { Divider, Typography } from "@material-ui/core";
import { faCashRegister } from "@fortawesome/pro-duotone-svg-icons";
import CashierShiftsService from "../../services/CashierShiftsService";
import apiClient from "../../auth/apiClient";
import { useTheme } from "@material-ui/core/styles";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { endCashierShift } from '../../state/slices/shiftSession/shiftSession';

const useUserActions = () => {
  const shiftSession = useSelector((state) => state.shiftSession);
  const cashierShiftsService = new CashierShiftsService(apiClient);
  const currentUser = useSelector(state => state.user);
  const { showDialog } = useConfirmationDialog();
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const activeShift = shiftSession?.shiftID > 0;

  const handleEndShift = async () => {
    await cashierShiftsService.endShift(shiftSession.shiftID, currentUser.UserID);
  };

  const handleLogOut = async () => {
    let msg = (
      <>
      <Divider style={{ marginBottom: 10, marginTop: -12 }} />
        <FontAwesomeIcon
          icon={faSignOut}
          size="4x"
          className={clsx(["animate__animated", "animate__tada"])}
          style={{
            color: theme.palette.primary,
            float: "left",
            alignItems: "baseline",
            marginRight: 15
          }}
        />
        <Typography variant="h6" style={{}}>
          Are you sure you want to log out?
        </Typography>
        <div style={{ clear: "both" }}></div>
        {activeShift && (
          <>
          <Divider style={{ marginBottom: 10 }} />
          <FontAwesomeIcon
            icon={faCashRegister}
            size="4x"
            className={clsx(["animate__animated", "animate__tada"])}
            style={{
              color: theme.palette.primary,
              float: "left",
              alignItems: "baseline",
              marginRight: 15
            }}
          />
          <Typography variant="h6" style={{}}>
            You still have an active cashier session
          </Typography>
          <div style={{ clear: "both" }}></div>
          </>
        )}
      </>
     );

    let continueDelete = await showDialog({
      title: `Log Out  ${currentUser.Username}?`,
      message: msg,
      buttons: [
        { name: "Yes", color: "secondary" },
        { name: "Cancel", color: theme.palette.text.disabled }
      ]
    });
    
    if (continueDelete === "Yes") {
      if (activeShift) { 
        await handleEndShift();
      }
      history.push("/logout");
    } 
  };

  return { handleEndShift, handleLogOut }
};

export default useUserActions