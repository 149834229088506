import { useEffect, useState } from "react";
import useAuthContext from "./useAuthContext";

export default function useManagedAuth() {
  const { authReducer, refreshUserSession } = useAuthContext();
  const [authData] = authReducer;
  const [token, setToken] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const isAuth = authData.authStatus == "AUTHENTICATED";
    setIsAuthenticated(isAuth);
    
    if (authData.currentToken || !isAuth) {
      setToken(authData.currentToken);
    }
  }, [authData]);

  const refreshToken = () => {
    refreshUserSession();
  };

  return {
    token,
    refreshToken,
    isAuthenticated,
  };
}
