import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";

/* Components */
import ReactHowler from "react-howler";

const useHowler = ({ src }) => {
  const [howlerState, setHowlerState] = useState({
    playing: false,
    loop: false,
  });

  const handleHowlerStop = useCallback(() => {
    if (!howlerState.loop)
      setHowlerState({
        playing: false,
        loop: false,
      });
  }, [howlerState.loop]);

  const Howler = React.useMemo(() => {
    const HowlerComponent = ({ identifier }) => {
      return (
        <div
          data-testid={identifier}
          data-playing={howlerState.playing}
          data-looping={howlerState.loop}
        >
          {/* This renders as an empty div on its own -- so wrapping in another div with attributes for test assertions */}
          <ReactHowler
            src={src}
            playing={howlerState.playing}
            loop={howlerState.loop}
            onEnd={handleHowlerStop}
          />
        </div>
      );
    };
    return HowlerComponent;
  }, [src, howlerState.playing, howlerState.loop]);

  const play = useCallback((options = {}) => {
    const { loop } = options;
    setHowlerState({
      playing: true,
      loop: loop ?? false,
    });
  }, []);

  const stop = useCallback(() => {
    setHowlerState({
      playing: false,
      loop: false,
    });
  }, []);

  return {
    Howler,
    play,
    playing: howlerState.playing,
    looping: howlerState.loop,
    stop,
  };
};

export default useHowler;

useHowler.propTypes = {
  src: PropTypes.string,
};
