import { useEffect } from "react";
import { HubConnectionState } from "@microsoft/signalr";
import usePortalHubConnection from "./usePortalHubConnection";
import usePortalHubActions from "./usePortalHubActions";
import useManagedAuth from "../useManagedAuth";
import useTimeout from "../useTimeout";

/*
  THIS IS NOT TO BE USED IN REGULAR COMPONENTS. JUST TO ESTABLISH THE ROOT LEVEL CONENCTION
*/
export default function usePortalHubConnectionManager() {
  const RETRY_INTERVAL_SECONDS = 10;
  const RECONNECTION_DELAY_MILLISECONDS = 5000;

  const { token, isAuthenticated } = useManagedAuth();
  const {
    isOpen,
    connectionState,
    connection,
    start,
    stop,
  } = usePortalHubConnection();

  const { subscribe, unsubscribe, invoke } = usePortalHubActions({
    connection
  });

  const isDisconnectedAndHasToken = () => {
    return token && connectionState == HubConnectionState.Disconnected;
  };

  const { setExpirationDt, cancelTimeout } = useTimeout({
    onTimeout: () => {
      if (!isDisconnectedAndHasToken()) {
        return;
      }

      connect();

      // Queue up next reconnection attempt
      setTimeout(() => {
        if (isDisconnectedAndHasToken()) {
          queueConnect();
        }
      }, RECONNECTION_DELAY_MILLISECONDS);
    },
  });

  useEffect(() => {
    console.log("Hub connection state change:", connectionState);
    if (isDisconnectedAndHasToken()) {
      queueConnect();
    } else {
      cancelTimeout();
    }
  }, [connectionState]);

  useEffect(() => {
    if (!token) {
      return;
    }
    connect();
  }, [token]);

  useEffect(() => {
    if (!isAuthenticated) {
      stop();
    }
  }, [isAuthenticated]);

  const connect = async () => {
    try {
      start(token);
    } catch (err) {
      console.error("SIGR CONNECT", err);
      queueConnect();
    }
  };

  const queueConnect = () => {
    var expirationDt = new Date();
    expirationDt.setSeconds(expirationDt.getSeconds() + RETRY_INTERVAL_SECONDS);
    setExpirationDt(expirationDt);
  };

  return {
    isOpen,
    connectionState,
    portalHub: {
      subscribe,
      unsubscribe,
      invoke,
      isOpen,
      connection,
    },
  };
}
