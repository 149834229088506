import { useLocation } from "react-router-dom";

const useQueryParams = () => {
  let result = {};
  const {search} = useLocation();
  const urlParams = new URLSearchParams(search);
  for (const [key, value] of urlParams.entries()) {
    result[key] = value;
  }
  return result;
};

export default useQueryParams;
