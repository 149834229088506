import React, { useState } from 'react'
import { useHistory } from 'react-router-dom';

export const useViewGroupContract = () => {
    const history = useHistory();

    const handleViewGroupContract = (contractId) => {
        history.push("/groupcontracts/details", { contractId });
    }

    return {
        handleViewGroupContract
    }
}