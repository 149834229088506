import _ from "lodash";
import { shallowEqual, useSelector } from "react-redux";
import { ENTITY_TYPE } from "../../constants";

const useContextDevices = (selectedOnly) => {
    const facilityDevices = useSelector((state) => getDevicesByFacility(state.entities.Context, state.entityScope, selectedOnly), shallowEqual);
    return facilityDevices;
}

function getDevicesByFacility(context, entityScope, selectedOnly) {
    var results = [];
    if (_.isNil(entityScope.facilityGroupId)) {
    results.push({ name: context.name, id: context.entityid, devices: getDevicesForCurrentContext(context) });
    } else {
    getDevicesForFacilityGroup(entityScope, results, selectedOnly);
    }
    return results;
}

function getDevicesForCurrentContext(context) {
    if (_.isNil(context.children) || context.children.length <= 0) {
        return [];
    }
    return getDevicesOrderAlphabetically(context.children);
}

function getDevicesForFacilityGroup(entityScope, results, selectedOnly) {
    if (_.isNil(entityScope.available)) { return; }
    for (let facility of entityScope.available) {
        if (_.isNil(facility.children) || facility.children.length <= 0) {
        continue;
        }

        if (selectedOnly && entityScope.selected.filter(x => x.id == facility.id).length == 0) { continue; }

        results.push({ name: facility.name, id: facility.id, devices: getDevicesOrderAlphabetically(facility.children) })
    }
}

function getDevicesOrderAlphabetically(entityList) {
    let devices = [];
    (function TryAddDevices(entityList) {
        if (_.isNil(entityList) || entityList.length <= 0) { return; }
        for (let entity of entityList) {
        // OR on typeid/entityType to support both entity context and available context naming schema
        if (entity.typeid === ENTITY_TYPE.Device || entity.entityType === ENTITY_TYPE.Device) {
            // OR on entityid/id to support both entity context and available context naming schema
            devices.push({ name: entity.name, entityid: entity.entityid || entity.id });
        } else if (entity.children) {
            TryAddDevices(entity.children);
        }
        }
    })(entityList);
    return _.orderBy(devices, ["name"], ["asc"]);
}


export default useContextDevices;