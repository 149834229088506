import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";

// Returns the devices that should be listened listening for changes
// TEMP NAME: useDeviceListeners (planning on changing)
export const useDeviceListeners = () => {
  const currentContext = useSelector((state) => state.entities.Context);
  const [listeningEntities, setListeningEntities] = useState([]);
  const listeningEntitiesRef = useRef(listeningEntities);
  const callCenterQueue = useSelector((state) => state.callcenter.Queue);

  useEffect(() => {
    listeningEntitiesRef.current = listeningEntities;
  }, [listeningEntities]);

  useEffect(() => {
    const updatedEntities = callCenterQueue?.reduce((acc, call) => {
      if (call?.deviceID && !acc.includes(call.deviceID)) {
        acc.push(call.deviceID);
      }
      return acc;
    }, []);

    if (updatedEntities && updatedEntities.length > 0) {
      setListeningEntities((prev) => [
        ...new Set([...prev, ...updatedEntities]),
      ]);
    }
  }, [callCenterQueue]);

  useEffect(() => {
    const collectDeviceIds = (children, acc, visited) => {
      children.forEach((child) => {
        if (visited.has(child.entityid)) return;
        visited.add(child.entityid);

        if (child.details.type === "Device" && child.entityid && !acc.includes(child.entityid)) {
          acc.push(child.entityid);
        }

        if (child.children && child.children.length > 0) {
          collectDeviceIds(child.children, acc, visited);
        }
      });
    };

    if (!currentContext?.children) return;

    const currentContextDeviceIds = [];
    const visited = new Set();
    collectDeviceIds(currentContext.children, currentContextDeviceIds, visited);

    // Only update the state if there are new device IDs to add
    setListeningEntities((prev) => {
      const newDeviceIds = currentContextDeviceIds.filter(id => !prev.includes(id));
      if (newDeviceIds.length > 0) {
        return [...new Set([...prev, ...newDeviceIds])];
      }
      return prev;
    });
  }, [currentContext]);

  const isBeingListened = (entityId) => {
    return listeningEntitiesRef.current.includes(entityId);
  };

  return {
    listeningEntities,
    isBeingListened,
  };
};
