import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Peer from "simple-peer";
import useHubContext from "../useHubContext";
import { IceServers } from "./serverConfig";
import { useSelector } from "react-redux";
import _                from "lodash";
import useTurnServerSettings from "../useTurnServerSettings";

const useDeviceRTC = (deviceID, config) => {
  const { portalHub } = useHubContext();
  const { replaceTurnSettings } = useTurnServerSettings();
  const deviceVideoRef = useRef({});
  const currentUser = useSelector((state) => state.user); //redux user
  const myPeer = useRef();
  const [isConnected, setConnected] = React.useState(false);
  
  useEffect(() => {
    portalHub.subscribe(`${deviceID}_CALL_CENTER_CALL_ACCEPTED`, (payload) => {
      const signalData = JSON.parse(payload.signalData);
      myPeer.current.signal(signalData);
    });

    return () => portalHub.unsubscribe(`${deviceID}_CALL_CENTER_CALL_ACCEPTED`);
  }, [deviceID]);

  const shouldSendVideoStream = () => {
    // safari only works when video is set to true in stream. This should ideally be removed when the iOS issues with call center have been resolved
    return navigator.userAgent.toLowerCase().indexOf('safari') > -1 && window.safari === undefined && navigator.userAgent.toLowerCase().indexOf('chrome') < 0;
  }

  const startCall = async () => {
    
    console.log("useRTC:startCall invoked");
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: config.audio,
        video: shouldSendVideoStream() ? true : config.video,
      });
      
      const replacedIceServers = replaceTurnSettings(IceServers);
      
      const peer = new Peer({
        initiator: config.initiator,
        trickle: false,
        config: {
          iceServers: replacedIceServers,
        },
        stream,
        offerOptions: {offerToReceiveVideo: true}
      });

      myPeer.current = peer;

      peer.on("signal", (data) => {
        console.log("peer on.signal", data);
        portalHub.invoke("CallCenter", {
          action: "CALL_DEVICE",
          callDetails: {
            deviceID,
            signalData: JSON.stringify(data),
            callCenterUserID: currentUser.UserID,
          },
        });
      });

      peer.on("stream", (stream) => {
        console.log("peer on.stream",stream, stream.getTracks());
        if (deviceVideoRef.current) {
          deviceVideoRef.current.srcObject = stream;
        }
      });

      peer.on("connect", (e)=>{
        console.log("peer on.connect",e);
      })

      peer.on("error", (err) => {
        console.log("peer on.error", err);
        console.error(err);
        endCall();
      });

      peer.on("track", (track,stream)=>{
        console.log("peer on.track", track, stream);
      })

      peer.on("close", (e) => {
        console.log("peer on.close",e)
        try{
            deviceVideoRef.current.srcObject
            .getTracks()
            .forEach((track) => track.stop());
            stream.getTracks().forEach((track) => track.stop());
        }catch {
          //do nothing
        }
      });
    } catch (err) {
      console.error(err);
    }
  };

  const endCall = () => {
    console.log("useRTC:endCall was invoked")
    try{
      if (myPeer.current) myPeer.current.destroy();
      portalHub.invoke("CallCenter", {
        action: "END_CALL",
        callDetails: {
          deviceID,
          callCenterUserID: currentUser.UserID,
        },
      });
    }catch{
      //blarp
    }

  };

  return { startCall, endCall, remoteMediaRef: deviceVideoRef };
};

useDeviceRTC.defaultProps = {
  config: { video: true, audio: true, initiator: true },
};

useDeviceRTC.propTypes = {
  deviceID: PropTypes.string,
  config: PropTypes.shape({
    initiator: PropTypes.bool,
    video: PropTypes.bool,
    audio: PropTypes.bool,
  }),
  onConnecting: PropTypes.func,
  onConnected: PropTypes.func,
  onDisconnected: PropTypes.func,
};

export default useDeviceRTC;
