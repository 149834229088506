import { useCallback } from "react";

/* State */
import { useSelector, useDispatch } from "react-redux";
import {
  addPeripheral as addPeripheralRD,
  updatePeripheral as updatePeripheralRD,
  removePeripheral as removePeripheralRD,
} from "../../state/slices/peripherals";

/* Services */
import apiClient from "../../auth/apiClient";
import PeripheralService from "../../services/PeripheralService";

const service = new PeripheralService(apiClient);

const usePeripherals = () => {
  const dispatch = useDispatch();

  const peripherals = useSelector((state) => {
    return state?.peripherals?.peripherals?.slice();
  });

  const addPeripheral = useCallback(async (peripheral) => {
    const result = await service.createPeripheral(peripheral);
    dispatch(addPeripheralRD(result.data));
  }, []);

  const updatePeripheral = useCallback(async (peripheral) => {
    const result = await service.updateSinglePeripheral(peripheral);
    dispatch(updatePeripheralRD(result.data));
  }, []);

  const removePeripheral = useCallback(async (peripheral) => {
    await service.deletePeripheral(peripheral.entityID, peripheral.peripheralID);
    dispatch(removePeripheralRD(peripheral));
  });

  return {
    peripherals,
    addPeripheral,
    updatePeripheral,
    removePeripheral,
  };
};

export default usePeripherals;