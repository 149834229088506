/* Services */
import UserService from "../../services/UserService";
import ValidationAccountService from "../../services/ValidationAccountService";
import apiClient from "../../auth/apiClient";

/* Style */
import { useTheme } from "@material-ui/core";

/* Utilities */
import { useEnqueueSnackbar } from "../useEnqueueSnackbar";

/* Hooks */
import { useConfirmationDialog } from "../useConfirmationDialog";

const userService = new UserService(apiClient);
const validationService = new ValidationAccountService(apiClient);

export default function useValidationAccountUserFunctions() {
  const enqueueSnackbar = useEnqueueSnackbar();
  const { textConfirmation } = useConfirmationDialog();
  const theme = useTheme();

  const resendInvitation = (user) => {
    userService
      .resendUserInvitation(user)
      .then((result) => {
        if (result?.status === 200) {
          enqueueSnackbar("Email has been sent", { variant: "success" });
        }
      })
      .catch((error) => {
        if (
          error?.response?.data?.includes("status is not FORCE_CHANGE_PASSWORD")
        )
          enqueueSnackbar("User has already logged in and set their password");
        else
          enqueueSnackbar(
            `Failed to resend user invitation: ${error.response.data}`,
            { variant: "error", tag: "FailedToResendUserInvitation" }
          );
      });
  };

  const associateToAccount = async (userIDs, validationAccountID) => {
    try {
      await validationService.associateValidationAccountUsers(
        validationAccountID,
        userIDs
      );
      enqueueSnackbar("Successfully associated users", { variant: "success" });
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Failed to update associated users", {
        variant: "error",
        tag: "FailedToUpdateAssociatedUsers",
      });
    }
  };

  const updateValidationAccountUsers = async (
    addedIds,
    deletedIds,
    validationAccountID
  ) => {
    try {
      await validationService.updateValidationAccountUsers(
        validationAccountID,
        addedIds,
        deletedIds
      );
      enqueueSnackbar("Successfully associated users", { variant: "success" });
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Failed to update associated users", {
        variant: "error",
        tag: "FailedToUpdateAssociatedUsers",
      });
    }
  };

  const deleteValidationAccountUser = async (user, facilityID, validationAccountID) => {
    let continueDelete = await textConfirmation({
      title: `DELETE ${user.firstName} ${user.lastName}?`,
      message:
        "To confirm deletion of this user, please enter the first and last name of the user in the box below.",
      textConfirmation: `${user.firstName} ${user.lastName}`,
      buttons: [
        { name: "Yes", color: "secondary" },
        { name: "Cancel", color: theme.palette.text.disabled },
      ],
    });
    if (continueDelete) {
      try {
        await userService.deleteUserForValidationAccount({
          userID: user.userId,
          scope: facilityID,
          accountID: validationAccountID
        });

        enqueueSnackbar("Successfully deleted validation account user", {
          variant: "success",
        });
      } catch (err) {
        console.error(err);
        enqueueSnackbar("Failed to delete validation account user", {
          variant: "error",
          tag: "FailedToDeleteUser",
        });
        return;
      }
    }
  };

  return {
    resendInvitation,
    associateToAccount,
    deleteValidationAccountUser,
    updateValidationAccountUsers,
  };
}
