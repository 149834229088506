import React, { useCallback, useState } from "react";

/* Components */
import { ToggleButton } from "@material-ui/lab";
import { Box } from "@material-ui/core";
import CodeReader from "../components/CodeReader";

/* Utilities */
import { debounce } from "lodash";

const ticketRegex =
  "^TicketID:[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$";
const urlRegex =
  "^(https?://)?([A-Za-z-.]){1}(:[0-9]{2,5})?.*/mobilepay/[0-9A-Fa-f]{8}[-]?(?:[0-9A-Fa-f]{4}[-]?){3}[0-9A-Fa-f]{12}";
const guidRegex =
  "[({]?[a-fA-F0-9]{8}[-]?([a-fA-F0-9]{4}[-]?){3}[a-fA-F0-9]{12}[})]?";

/**
 *
 * @param {Object} readerStyle The style used by the reader component and toggle
 * @param {boolean} ticketsOnly If true, the reader will only attempt to validate the read as a ticket
 * @param {string} toggleButtonText The text content that is displayed in the toggle button for the reader
 */
const useCredentialScanner = ({
  readerStyle,
  ticketsOnly,
  toggleButtonTextOn,
  toggleButtonTextOff
}) => {
  const [isScanning, setIsScanning] = useState(false);
  const [credentialRead, setCredentialRead] = useState();
  const [deviceID, setDeviceID] = useState();
  
  const forceScan = (content) => {
    ticketsOnly ? handleTicketRead(content) : handleGenericRead(content);
  };

  const debouncedRead = useCallback(
    (readResult) => {
      debounce(
        ticketsOnly
          ? handleTicketRead(readResult)
          : handleGenericRead(readResult),
        1000
      );
    },
    [ticketsOnly]
  );

  const handleTicketRead = useCallback((readResult) => {
    if (!readResult || readResult === "") return;

    // first determine if the encoded value matches are predefined formats
    const tests = [
      new RegExp(ticketRegex, "i"),
      new RegExp(urlRegex, "i"),
      new RegExp(guidRegex, "i"),
    ];
    const validRegexers = tests.filter((regexer) => regexer.test(readResult));
    const regexer = validRegexers[0];
    if (!regexer) {
      return;
    }
    // snag the ticket id
    const ticketParseResult = new RegExp(guidRegex, "g").exec(readResult)[0];
    if (!ticketParseResult) {
      return;
    }

    setCredentialRead(ticketParseResult);
    setIsScanning(false);
  }, []);

  const handleGenericRead = useCallback((readResult) => {
    setCredentialRead(readResult);
    setIsScanning(false);
  }, []);

  const Reader = React.useMemo(() => {
    const CodeReaderComponent = () => {
      return (
        <>
          <div className={readerStyle.codeReader}>
            <CodeReader
              handleRead={debouncedRead}
              handleError={(err) => {}}
              isScanning={isScanning}
              device={deviceID}
              onChange={(device) => {
                setDeviceID(device);
              }}
            />
          </div>
          <Box display="flex" justifyContent="center" alignItems="center">
            <ToggleButton
              classes={{
                root: readerStyle.toggleButtonRoot,
                selected: readerStyle.toggleButtonSelected,
              }}
              value={isScanning}
              selected={isScanning}
              onChange={() => {
                setIsScanning((prev) => !prev);
              }}
              data-id="scan-button"
            >
              {isScanning ? toggleButtonTextOn : toggleButtonTextOff}
            </ToggleButton>
          </Box>
        </>
      );
    };
    return CodeReaderComponent;
  }, [isScanning, readerStyle, deviceID]);

  return { Reader, credentialRead, forceScan };
};

export default useCredentialScanner;
