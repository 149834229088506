import { shallowEqual, useSelector } from "react-redux";
import { GetAllDevicesIds } from "../state/slices/entities";

const useDeviceIDs = () => {
  return useSelector(
    (state) => GetAllDevicesIds(state.entities?.EntityList ?? []),
    shallowEqual
  );
};

export default useDeviceIDs;
