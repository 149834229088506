/* Hooks */
import useHubContext from "../useHubContext";

/* State */
import { useSelector, shallowEqual } from "react-redux";

/* Utilities */
import * as _ from "lodash";

/* Constants */
import * as c from "../../constants";

export function getAllSettings(entityList) {
  const settings = [];
  (function walk(entityList) {
    if (!entityList) return;
    if (entityList.settings)
      settings.push({
        entityID: entityList.entityid,
        settings: entityList.settings,
      });
    if (entityList.children) {
      for (let item of entityList.children) {
        walk(item);
      }
    }
  })(entityList);
  return settings;
}

const useEntitySettings = () => {
  const { portalHub } = useHubContext();

  const entitySettings = useSelector(
    (state) => getAllSettings(state.entities.EntityList),
    (old, current) => JSON.stringify(old) === JSON.stringify(current),
    shallowEqual
  );

  const getSettingValue = (entityID, settingName) => {
    const entity = _.find(entitySettings, {
      entityID,
    });
    if (!entity) return undefined;
    const setting = _.find(entity.settings, { name: settingName });
    return setting?.value;
  };

  const broadcastSettingChange = (options) => {
    const { target, settingName, settingValue } = options;

    portalHub.invoke(c.Hub.BROADCAST, {
      Method: "SETTING_CHANGED",
      Target: target,
      IncludeSelf: false,
      Payload: {
        settingName,
        settingValue,
      },
    });
  };

  const broadcastBulkSettingChange = (options) => {
    const { target, payload } = options;

    return portalHub.invoke(c.Hub.BROADCAST, {
      Method: "BULK_SETTINGS_CHANGED",
      Target: target,
      IncludeSelf: false,
      Payload: payload,
    });
  };

  return {
    getSettingValue,
    broadcastSettingChange,
    broadcastBulkSettingChange,
  };
};

export default useEntitySettings;
