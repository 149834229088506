import React, { createContext, useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Input,
  DialogContent,
  Typography,
  TextField,
  Grid,
  Divider,
  DialogActions,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Check";
import CancelIcon from "@material-ui/icons/Cancel";
import clsx from "clsx";

const CONFIRMATION_TYPES = {
  TextConfirmation: 0,
  ButtonConfirmation: 1,
  ReasonConfirmation: 2,
};

const ConfirmationDialogContext = createContext();

const ConfirmationDialogProvider = ({ children }) => {
  const [dialogOptions, setDialogOptions] = useState({
    buttons: [],
    title: "",
    message: "",
    open: false,
  });

  const openDialog = ({
    title,
    message,
    buttons,
    confirmationCallback,
    paperProps,
  }) => {
    setDialogOptions({
      title,
      message,
      buttons,
      open: true,
      confirmationCallback,
      type: CONFIRMATION_TYPES.ButtonConfirmation,
      paperProps,
    });
  };

  const openTextConfirmationDialog = ({
    title,
    message,
    textConfirmation,
    confirmationCallback,
  }) => {
    setDialogOptions({
      title,
      message,
      type: CONFIRMATION_TYPES.TextConfirmation,
      textConfirmation,
      open: true,
      confirmationCallback,
    });
  };

  const openReasonConfirmation = ({
    title,
    message,
    required,
    confirmationCallback,
  }) => {
    setDialogOptions({
      title,
      message,
      type: CONFIRMATION_TYPES.ReasonConfirmation,
      open: true,
      required,
      confirmationCallback,
    });
  };

  const handleClose = () => {
    dialogOptions.confirmationCallback(false);
    setDialogOptions({ open: false });
  };

  const handleTextSubmit = () => {
    dialogOptions.confirmationCallback(true);
    setDialogOptions({ open: false });
  };

  const handleButtonClick = (buttonName) => {
    dialogOptions.confirmationCallback(buttonName);
    setDialogOptions({ open: false });
  };

  const handleReasonSubmit = (reason) => {
    if (dialogOptions.required === false && reason === undefined)
      reason = "N/A";
    dialogOptions.confirmationCallback(reason);
    setDialogOptions({ open: false });
  };

  return (
    <ConfirmationDialogContext.Provider
      value={{ openDialog, openTextConfirmationDialog, openReasonConfirmation }}
    >
      <ConfirmationDialog
        {...dialogOptions}
        dialogClose={handleClose}
        onTextConfirmationSubmit={handleTextSubmit}
        onReasonConfirmationSubmit={handleReasonSubmit}
        onOptionButtonClick={handleButtonClick}
      />
      {children}
    </ConfirmationDialogContext.Provider>
  );
};

const ConfirmationDialog = ({ open, title, dialogClose, type, ...options }) => {
  const classes = useConfirmationStyles();

  const getContentByType = (type) => {
    switch (type) {
      case CONFIRMATION_TYPES.TextConfirmation:
        return <TextConfirmation {...options} />;
      case CONFIRMATION_TYPES.ButtonConfirmation:
        return <ButtonConfirmation {...options} />;
      case CONFIRMATION_TYPES.ReasonConfirmation:
        return <ReasonConfirmation {...options} />;
    }
  };

  const ReasonConfirmation = ({
    message,
    onReasonConfirmationSubmit,
    required,
  }) => {
    const [reason, setReason] = useState();

    const handleReasonConfirmationSubmit = () => {
      onReasonConfirmationSubmit(reason);
      setReason();
    };

    const closeReasonConfirmation = () => {
      setReason();
      dialogClose();
    };

    const handleInputChange = (e) => {
      setReason(e.target.value);
    };

    return (
      <>
        <DialogContent style={{ overflow: "hidden" }}>
          <Typography>{message}</Typography>
          <div style={{ textAlign: "center" }}>
            <TextField
              fullWidth
              style={{ paddingRight: 20 }}
              className={clsx([classes.reasonConfirmationInput, "reason"])}
              value={reason}
              onChange={handleInputChange}
              variant="outlined"
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={1} justify="flex-end">
            <Grid item>
              <Button
                className={clsx([classes.textSubmitBtn, "submit"])}
                onClick={handleReasonConfirmationSubmit}
                variant="contained"
                color="primary"
                disabled={required && (!reason || reason.trim().length === 0)}
                startIcon={<CheckIcon />}
              >
                Submit
              </Button>
            </Grid>
            <Grid item>
              <Button
                className={clsx([classes.textSubmitBtn, "cancel"])}
                onClick={closeReasonConfirmation}
                variant="contained"
                startIcon={<CancelIcon />}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </>
    );
  };

  const ButtonConfirmation = ({ buttons, onOptionButtonClick, message }) => {
    return (
      <>
        <DialogContent
          style={{ overflow: "hidden" }}
          className="btn-confirmation-message"
        >
          <Typography>{message}</Typography>
        </DialogContent>
        <DialogActions>
          <div
            className={clsx(["btn-confirmation-btns", classes.btnContainer])}
            container
            spacing={2}
          >
            {buttons.map(({ name, color }, index) => {
              return (
                <Button
                  key={index}
                  variant="contained"
                  color={color}
                  onClick={() => onOptionButtonClick(name)}
                  className={clsx(name)}
                  data-id={index}
                >
                  {name}
                </Button>
              );
            })}
          </div>
        </DialogActions>
      </>
    );
  };

  const TextConfirmation = ({
    textConfirmation,
    message,
    onTextConfirmationSubmit,
  }) => {
    const [inputVal, setInputval] = useState();

    useEffect(() => {
      return () => setInputval();
    }, []);

    const handleTextConfirmationSubmit = () => {
      if (inputVal === textConfirmation) {
        onTextConfirmationSubmit();
        setInputval();
      }
    };

    const closeTextConfirmation = () => {
      setInputval();
      dialogClose();
    };

    const handleInputChange = (e) => {
      setInputval(e.target.value);
    };

    return (
      <>
        <DialogContent style={{ overflow: "hidden" }}>
          <Typography>{message}</Typography>
          <Typography
            variant="body2"
            style={{ marginTop: 15, textAlign: "center" }}
          >
            Type{" "}
            <span
              style={{ paddingLeft: 6, paddingRight: 6, fontWeight: "bold" }}
            >
              {textConfirmation}
            </span>{" "}
            to Continue
          </Typography>
          <div style={{ textAlign: "center" }}>
            <TextField
              fullWidth
              style={{ paddingRight: 20 }}
              className={clsx([
                classes.textConfirmationInput,
                "confirmation-input",
              ])}
              value={inputVal}
              onChange={handleInputChange}
              variant="outlined"
            />
          </div>
          <Divider style={{ marginTop: 5, marginBottom: 5 }} />
        </DialogContent>
        <DialogActions>
          <Divider />
          <Grid container spacing={1} justify="flex-end">
            <Grid item>
              <Button
                className={clsx([classes.textSubmitBtn, "submit"])}
                onClick={handleTextConfirmationSubmit}
                variant="contained"
                color="primary"
                disabled={inputVal != textConfirmation}
                startIcon={<CheckIcon />}
              >
                Submit
              </Button>
            </Grid>
            <Grid item>
              <Button
                className={clsx([classes.textSubmitBtn, "cancel"])}
                onClick={closeTextConfirmation}
                variant="contained"
                startIcon={<CancelIcon />}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={dialogClose}
      disableScrollLock={true}
      style={{ overflow: "hidden" }}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      className="dialog-confirmation"
      PaperProps={options.paperProps}
    >
      <DialogTitle className="dialog-title">{title}</DialogTitle>
      {getContentByType(type)}
    </Dialog>
  );
};

const useConfirmationStyles = makeStyles((theme) => ({
  textConfirmationInput: {
    margin: theme.spacing(1),
  },
  reasonConfirmationInput: {
    margin: theme.spacing(1),
  },
  textSubmitBtn: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  btnContainer: {
    display: "inline-flex",
    justifyContent: "flex-end",
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    "& button": { marginRight: theme.spacing(3) },
  },
}));

ConfirmationDialog.defaultProps = {
  buttons: [],
  onTextConfirmationSubmit: () => {},
};

export const useConfirmationDialog = () => {
  const {
    openDialog,
    openTextConfirmationDialog,
    openReasonConfirmation,
  } = useContext(ConfirmationDialogContext);

  const textConfirmation = ({ ...options }) =>
    new Promise((res) => {
      openTextConfirmationDialog({ confirmationCallback: res, ...options });
    });

  const showDialog = ({ ...options }) =>
    new Promise((res) => {
      openDialog({ confirmationCallback: res, ...options });
    });

  const reasonConfirmation = ({ ...options }) =>
    new Promise((res) => {
      openReasonConfirmation({ confirmationCallback: res, ...options });
    });

  return { textConfirmation, showDialog, reasonConfirmation };
};

export default ConfirmationDialogProvider;
