import {useEffect, useState, useCallback} from "react";
import * as c from "../constants";
import { setEntityProperty, setEntityStateIfOffline } from "../state/slices/entities";
import useTimeout from "./useTimeout";
import {useFlags} from "launchdarkly-react-client-sdk";

const useDeviceConnectionManager = ({portalHub, dispatch}) => {
  const [deviceIDs, setDisconnectedDevices] = useState([]);
  const [timeoutOccurred, setTimeoutOccurred] = useState(null);
  const {connectedDevicesDisconnectDelay} = useFlags();

  const {setExpirationDt} = useTimeout({
    onTimeout: () => setTimeoutOccurred(new Date())
  });

  useEffect(() => {
    if (portalHub.isOpen) {
      portalHub.subscribe(c.DEVICE_DISCONNECTED_TOPIC, async (message) => {
        const messageData = JSON.parse(message);
        setDisconnectedDevices([...deviceIDs, messageData.EntityID])
        const secondsDelay = connectedDevicesDisconnectDelay ?? 10;
        const t = new Date();
        t.setSeconds(t.getSeconds() + secondsDelay);
        setExpirationDt(t);
      });
    }

    return () => {
      portalHub.unsubscribe(c.DEVICE_DISCONNECTED_TOPIC);
    };
  }, [portalHub.isOpen]);

  useEffect(() => {
    if (portalHub.isOpen) {
      portalHub.subscribe(c.DEVICE_CONNECTED_TOPIC, async (message) => {
        const messageData = JSON.parse(message);

				dispatch(
					setEntityStateIfOffline({
						entityid: messageData.EntityID,
						value: {},
					})
				);

        setDisconnectedDevices(deviceIDs.filter((id) => id !== messageData.EntityID))
      });
    }

    return () => {
      portalHub.unsubscribe(c.DEVICE_CONNECTED_TOPIC);
    };
  }, [portalHub.isOpen]);

  useEffect(() => {
    if (!timeoutOccurred || deviceIDs.length === 0) {
      return
    }

    deviceIDs.forEach(id => {
      dispatch(
        setEntityProperty({
          entityid: id,
          property: "state",
          value: null,
        })
      );
    });
    setDisconnectedDevices([]);
  }, [timeoutOccurred]);

  return {};
};

export default useDeviceConnectionManager;
