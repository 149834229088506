export const IceServers = [
  { urls: "stun:stun01.sipphone.com" },
  {
    urls: "<STUN_URL>",
  },
  {
    urls: "<TURN_URL>",
    credential: "<TURN_PASSWORD>",
    username: "<TURN_USERNAME>",
  },
];
