import { shallowEqual, useSelector } from "react-redux";

const useCurrentFacility = () => {
  const facilityID = useSelector(
    (state) => state.entities?.ContextID,
    shallowEqual
  );
  const facilityName = useSelector((state) => state.entities?.Context?.name);
  return {
    facilityID: facilityID,
    facilityName: facilityName ?? "Facilities",
  };
};

export default useCurrentFacility;
