import { useRef, useState } from "react";

const useStateRef = (value) => {
  const ref = useRef(value);
  const [, forceRender] = useState(false);

  const updateState = (updated) => {
    ref.current = updated;
    forceRender((s) => !s);
  };

  return [ref, updateState];
};

export default useStateRef;
