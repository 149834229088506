import { useEffect } from "react";
import {
  addViewer,
  removeViewer,
  setConnection,
  removeFromQueue,
  fetchQueueByUserID,
} from "../../state/slices/callcenter";
import useHubContext from "../useHubContext";
import { useDispatch } from "react-redux";
import useCallCenterActions from "../useCallCenterActions";

// NOT TO BE USED OUTSIDE OF AppStoreProvider
// MAKING OWN FILE FOR READABILITY

const useCallCenterListeners = () => {
  const dispatch = useDispatch();
  const { portalHub, Connected: PortalHubConnected } = useHubContext();
  const { addCallToQueue } = useCallCenterActions();

  useEffect(() => {
    if (portalHub.isOpen) {
      portalHub.subscribe("CALL_QUEUED", (call) => {
        console.log("call queued: ", call);
        addCallToQueue(JSON.parse(JSON.parse(call).message));
      });

      portalHub.subscribe("CALL_DEQUEUED", (call) => {
        dispatch(
          removeFromQueue({
            callID: JSON.parse(JSON.parse(call).message).callID,
          })
        );
      });
    }

    return () => {
      portalHub.unsubscribe("CALL_QUEUED");
      portalHub.unsubscribe("CALL_DEQUEUED");
    };
  }, [PortalHubConnected]);

  const getFacilityCallCenterQueue = (currentUserId) => {
    dispatch(fetchQueueByUserID(currentUserId));
  };

  const handleBroadcast = (broadcast) => {
    console.log("BROADCAST: ", broadcast);

    switch (broadcast.method) {
      case "CC_ITEM_REMOVED":
        console.log("CC_ITEM_REMOVED", broadcast);
        dispatch(removeFromQueue(broadcast.payload));
        break;
      case "CC_ITEM_VIEWING":
        broadcast.payload.viewing === false
          ? dispatch(
              removeViewer({
                callID: broadcast.payload.callID,
                viewer: broadcast.payload.user.emailAddress,
              })
            )
          : dispatch(
              addViewer({
                callID: broadcast.payload.callID,
                viewer: broadcast.payload.user.emailAddress,
              })
            );
        break;
      case "CC_CONNECTION":
        dispatch(
          setConnection({
            callID: broadcast.payload.callID,
            user: broadcast.payload.user,
            state: broadcast.payload.state,
          })
        );
        break;
      default:
        break;
    }
  };

  return { initializeQueue: getFacilityCallCenterQueue, handleBroadcast };
};

export default useCallCenterListeners;
