import { useEffect, useState } from "react";
import AuthAuditService from "../../services/AuditService";
import apiClient from "../../auth/apiClient";

export default function useAuthAudit({ authData }) {
  const authAuditService = new AuthAuditService(apiClient);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [hasPerformedLogin, setHasPerformedLogin] = useState(false);
  const [username, setUsername] = useState(null);
  const [facilityId, setFacilityId] = useState(null);
  const [hasWrittenLogin, setHasWrittenLogin] = useState(false);

  useEffect(() => {
    setIsAuthenticated(authData?.authStatus == "AUTHENTICATED");
  }, [authData]);

  useEffect(() => {
    setUsername(isAuthenticated ? authData.userSession?.idToken?.payload.email : null);
    setFacilityId(isAuthenticated ? authData.currentFacility?.facilityID : null);
  }, [isAuthenticated]);

  useEffect(() => {
    const isAuthenticatedAndHasContextData = isAuthenticated && username && facilityId;
    const shouldWriteLoginAudit = hasPerformedLogin && hasWrittenLogin !== true;

    if (isAuthenticatedAndHasContextData && shouldWriteLoginAudit) {
      setHasWrittenLogin(true);
      authAuditService.createLoginAudit(username);
    }
  }, [isAuthenticated, username, facilityId, hasPerformedLogin]);

  const auditLogout = async () => {
    setHasWrittenLogin(false);
    return await authAuditService.createLogoutAudit(username);
  };

  const markAsUserHasLoggedIn = async () => {
    setHasPerformedLogin(true);
  };

  return {
    markAsUserHasLoggedIn,
    auditLogout,
  };
}
