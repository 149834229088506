import { HubConnectionState } from "@microsoft/signalr";

export default function usePortalHubActions({ connection }) {
  const invoke = (topic, ...args) => {
    if (!connection || connection.state != HubConnectionState.Connected) {
      console.warn("Invoke not available. Connection is not Connected. Topic:", topic, connection.state, args);
      return false;
    }

    console.log(`portalhub-invoke: ${topic}`, args);
    if (args.length) {
      connection.invoke(topic, ...args);
    } else {
      connection.invoke(topic);
    }
  };

  const subscribe = (topic, callback, dontlog) => {
    //which topic, at what level, what to call back
    if (connection.state != HubConnectionState.Connected) {
      console.warn("Subscribe not available. Connection is not Connected. Topic:", topic, connection.state);
      return;
    }
    
    connection.on(topic, callback);
    if(!dontlog){
      console.log(
        "%csubscription created for [" + topic + "]",
        "color:lightgreen"
      );
    }
  };

  const unsubscribe = (topic) => {
    if (!connection) {
      return;
    }

    connection.off(topic);
    console.log("%csubscription removed for [" + topic + "]", "color:orange");
  };

  return {
    subscribe,
    unsubscribe,
    invoke,
  };
}
