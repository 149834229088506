import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCountries, fetchSubdivisions } from '../state/slices/countries';

export const useCountry = () => {
  const [selectedCountryId, setSelectedCountryId] = useState();
  const { countries, subdivisions } = useSelector((state) => state.countries);
  const dispatch = useDispatch();

  useEffect(() => {
    if (countries.length === 0) {
      dispatch(fetchCountries());
    }
  }, [])

  useEffect(() => {
    if (selectedCountryId) {
      dispatch(fetchSubdivisions(selectedCountryId));
    }
  }, [selectedCountryId])

  return {
    countries,
    subdivisions,
    selectedCountryId,
    setSelectedCountryId
  }
}