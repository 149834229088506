import * as c from "../constants";
import moment from "moment";
import useHubContext from "./useHubContext";
import { shallowEqual, useSelector } from "react-redux";
import { isUndefined } from "lodash";
import { FindEntity } from "../state/slices/entities";
import * as _ from "lodash";

export const useTransientCount = (entity) => {
  const { portalHub, Connected: PortalHubConnected } = useHubContext();
  const currentUser = useSelector((state) => state.user, shallowEqual);

  const adjustTransientCount = (credentialTypeID) => {
    if (
      !isUndefined(entity) &&
      entity.entityID != -1 &&
      entity.entityID != null
    ) {
      portalHub.invoke(c.PORTAL_TRIGGER, {
        entityID: entity.entityID,
        topic: c.OCCUPANCY_DEMAND,
        action: "FacilitySpaceCount",
        message: JSON.stringify({
          EntityId: entity.entityID,
          CredentialTypeId: credentialTypeID,
          Increment: entity.deviceMode == c.DEVICE_MODE.ENTRY ? true : false,
          CreatedBy: currentUser.Username,
          CreatedDate: moment(Date.now()).format("YYYY-MM-DDTHH:mm:ssZ"),
        }),
      });
    }
  };
  return { adjustTransientCount };
};

export const useTransientCountV2 = (entityID) => {
  const { portalHub, Connected: PortalHubConnected } = useHubContext();
  const currentUser = useSelector((state) => state.user, shallowEqual);
  const entity = useSelector((state) => {
    const foundEntity = FindEntity(state.entities?.EntityList ?? [], entityID);
    return {
      entityID: foundEntity?.entityid,
      deviceMode:
        _.find(foundEntity?.settings ?? [], { name: "devicemode" })?.value ??
        null,
    };
  }, shallowEqual);

  const adjustTransientCount = (credentialTypeID) => {
    if (
      !isUndefined(entity) &&
      entity.entityID != -1 &&
      entity.entityID != null
    ) {
      portalHub.invoke(c.PORTAL_TRIGGER, {
        entityID: entity.entityID,
        topic: c.OCCUPANCY_DEMAND,
        action: "FacilitySpaceCount",
        message: JSON.stringify({
          EntityId: entity.entityID,
          CredentialTypeId: credentialTypeID,
          Increment: entity.deviceMode == c.DEVICE_MODE.ENTRY ? true : false,
          CreatedBy: currentUser.Username,
          CreatedDate: moment(Date.now()).format("YYYY-MM-DDTHH:mm:ssZ"),
        }),
      });
    }
  };

  const adjustTransientCountForRoaming = (entityIDToUse, credentialTypeID) => {
    if (!isUndefined(entityIDToUse) && entityIDToUse != null) {
      portalHub.invoke(c.PORTAL_TRIGGER, {
        entityID: entityIDToUse,
        topic: c.OCCUPANCY_DEMAND,
        action: "FacilitySpaceCount",
        message: JSON.stringify({
          EntityId: entityIDToUse,
          CredentialTypeId: credentialTypeID,
          Increment: true,
          CreatedBy: currentUser.Username,
          CreatedDate: moment(Date.now()).format("YYYY-MM-DDTHH:mm:ssZ"),
        }),
      });
    }
  };

  return {
    adjustTransientCount,
    adjustTransientCountForRoaming
  };
};
