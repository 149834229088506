import { useSelector } from "react-redux";

const useRoamingTerminal = (terminalID) => {
  const roamingCCTerminal = useSelector((state) =>
    state.cashier.roamingCCTerminals?.find(
      (x) => x.roamingCCTerminalID === terminalID
    )
  );

  return roamingCCTerminal;
};

export default useRoamingTerminal;
