// See: https://github.com/iamhosseindhv/notistack/pull/259
import React from "react";
import { useSnackbar as useDefaultSnackbar } from "notistack";
import AmiToast from "../components/AmiToast"

export const useEnqueueSnackbar = () => {
  const { enqueueSnackbar } = useDefaultSnackbar();
  const pushSnackbar = (
    message,
    options,
  ) => {
    enqueueSnackbar(message, {
      ...options,
      content: (key) => {
        const { variant, tag, title, fullwidth } = options || { variant:undefined, title:undefined, fullwidth:undefined };
        const returnMe = (
          <AmiToast 
            id={key}
            message={message}
            variant={variant}
            tag={tag}
            title={title}
            fullwidth={fullwidth}
          />
        );
        return returnMe;
    },
});
};
    pushSnackbar.displayName = "AmiToastWrapper";
    return pushSnackbar;
};

